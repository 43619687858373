.zoom {
  transition: transform 0.2s;
}

.zoom:hover {
  transform: scale(1.4);
}

.custom-start-video-call-btn {
  flex: 1;
  border: none !important;
  height: 37px !important;
  border-radius: 0.25rem 0 0 0.25rem !important; // Rounded only on the left side
}

.custom-settings-btn {
  height: 37px !important;
  color: #fff !important;
  border: none !important;
  border-left: 2px solid rgba(255, 255, 255, 0.3) !important;
  border-radius: 0 0.25rem 0.25rem 0 !important; // Rounded only on the right side
  padding: 0.5rem 0.5rem !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  cursor: pointer !important;
}
