//
// avatar.scss
//

.avatar-xs {
  height: 2.5rem;
  width: 2.5rem;
}

.avatar-sm {
  height: 3rem;
  width: 3rem;
}

.avatar-md {
  height: 4.5rem;
  width: 4.5rem;
}

.avatar-lg {
  height: 6rem;
  width: 6rem;
}

.avatar-xl {
  height: 7.5rem;
  width: 7.5rem;
}

.avatar-title {
  align-items: center;
  background-color: $primary;
  color: $white;
  display: flex;
  font-weight: $fw-medium;
  height: 100%;
  justify-content: center;
  width: 100%;
}

// avatar group
.avatar-group {
  padding-left: 12px;
  display: flex;
  flex-wrap: wrap;
  .avatar-group-item {
    margin-left: -12px;
    border: 2px solid $card-bg;
    border-radius: 50%;
    transition: all 0.2s;
    &:hover {
      position: relative;
      transform: translateY(-2px);
    }
  }
}

// Soft avatar
@mixin avatar-variant-soft($bg) {
  color: $bg;
  background-color: rgba($bg, 0.18);

  &[href] {
    &:hover,
    &:focus {
      color: $bg;
      text-decoration: none;
      background-color: rgba($bg, 0.4);
    }
  }
}

@each $color, $value in $theme-colors {
  .avatar {
    &.bg-#{$color} {
      &[href] {
        &:hover,
        &:focus {
          background-color: darken($value, 4%) !important;
        }
      }
    }
    &.bg-light {
      color: $body-color;

      &[href] {
        &:hover,
        &:focus {
          color: $body-color;
        }
      }
    }
  }

  .avatar-soft-#{$color} {
    @include avatar-variant-soft($value);
  }
}
