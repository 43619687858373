:root {
  [data-style="2"] {
    //  THEME
    $icon-primary: #564ab1;
    $icon-primary-darker: #392e88;
    $suvanto-color-dark: #038ddd;
    $blue: #556ee6;
    $indigo: #564ab1;
    $purple: #6f42c1;
    $pink: #e83e8c;
    $red: #f46a6a;
    $red2: #bd2c2c;
    $red3: #ff6e6e;
    $orange: #f1734f;
    $yellow: #ffd000;
    $green: #34c38f;
    $teal: #050505;
    $cyan: #50a5f1;
    $suvanto-light: #dfecff;
    $suvanto-dark: #42b6f8;
    $suvanto-darker-bg: #038ddd;
    $suvanto-var-blue: #558ee6;
    $suvanto-blue: #00bcf5; //4dc6f3// USED IN SMALL ICONS
    $suvanto-blue-3-1: #00a0d6;
    $suvanto-light-bg: #d1e1e7;
    $suvanto-blue-45-1: #0080a9; //#007DA3 //0080A9
    $suvanto-green: #49a02a; //8dd22c
    $suvanto-orange: #ff9b00;
    $suvanto-dark-blue: #224465;
    $loading-blue-dots: #0061db;
    $light-card: #f7f7f7;
    // scss-docs-end color-variables
    $chart-gateway: #0cc744;
    $chart-activity: #00a0d6;
    $chart-electricity: #0026ff; //#186bfc
    $chart-door: #8dd169; //0cc770
    $chart-bed: #b0b0b0;
    $chart-dining: #7871c4; // #adb3eb;
    $chart-wc: #ff9b00;
    $chart-color-purple: #ac52da;
    $chart-color-suvantoblue: #00bcf5;
    $statusbar-suvantoblue: #3bc5ee;
    $chart-color-lowhumidity: #7abcf7;
    $chart-color-temp: #ff954e;
    $chart-color-templow: #fcda69;
    $chart-color-temphigh: #ff4c2c;
    $yellow2: #df8600;
    // donut-chart-colors
    $chart-color1: #f5d663;
    $chart-color2: #f5a14a;
    $chart-color3: #48d0f8;
    $chart-color4: #3783cc;
    $chart-color5: #1c3763;
    $chart-color6: #0a9396;
    $chart-color7: #94d2bd;
    $green-card-messages: #81cfb3;
    $dark-background: #222736;

    $primary: $chart-color-purple;
    $darker-primary: $icon-primary-darker;

    //$secondary: $gray-600;
    $secondary: $suvanto-green;
    $tertiary: $suvanto-orange;
    $success: $green;
    $info: $cyan;
    $warning: $yellow;
    $warning2: $yellow2;
    $danger: $red;
    $danger2: $red2;
    $danger3: $red3;
    $light: $gray-200;
    $dark: $gray-800;
    $icon-primary: $icon-primary;

    // component variable
    --#{$prefix}primary: #{$primary};
    --#{$prefix}primary-rgb: #{to-rgb($primary)};
    --#{$prefix}secondary: #{$gray-700};
    --#{$prefix}secondary-rgb: #{to-rgb($gray-700)};
    //  text variable
    --#{$prefix}text-primary: #{$primary} !important;
    --#{$prefix}text-primary-rgb: #{to-rgb($primary)};

    --#{$prefix}text-darker-primary: #{$darker-primary} !important;
    --#{$prefix}text-darker-primary-rgb: #{to-rgb($darker-primary)} !important;
    --#{$prefix}text-secondary: #{$suvanto-green};
    --#{$prefix}text-secondary-rgb: #{to-rgb($suvanto-green)};
    // Menu-colors
    --#{$prefix}menu-item-active-color: #{$primary};

    // Group cards modal info
    .group-modal-info:hover {
      color: #{$primary};
    }
    // BTN
    .btn.btn-primary.disabled {
      background-color: #{$primary};
      border-color: #{$primary};
    }
    .btn.btn-secondary.disabled {
      background-color: #{$suvanto-green};
      border-color: #{$suvanto-green};
    }
    .btn-primary {
      --#{$prefix}btn-bg: #{$primary};
      --#{$prefix}btn-border-color: #{$primary};
      --#{$prefix}btn-hover-bg: #{darken($primary, 4%)} !important;
      --#{$prefix}btn-active-bg: #{darken($primary, 7%)} !important;
      --#{$prefix}btn-active-border-color: #{darken($primary, 7%)} !important;
    }
    .btn-secondary {
      --#{$prefix}btn-bg: #{$suvanto-green};
      --#{$prefix}btn-border-color: #{$suvanto-green};
      --#{$prefix}btn-hover-bg: #{darken($suvanto-green, 4%)} !important;
      --#{$prefix}btn-active-bg: #{darken($suvanto-green, 7%)} !important;
      --#{$prefix}btn-active-border-color: #{darken($suvanto-green, 7%)} !important;
    }
    .btn-outline-primary {
      --#{$prefix}btn-bg: transparent;
      --#{$prefix}btn-color: #{$primary};
      --#{$prefix}btn-border-color: #{$primary};
      --#{$prefix}btn-hover-border-color: #{$primary};
      --#{$prefix}btn-hover-bg: #{$primary};
      --#{$prefix}btn-hover-color: #{$white};
    }
    .btn-outline-secondary {
      --#{$prefix}btn-bg: transparent;
      --#{$prefix}btn-color: #{$suvanto-green};
      --#{$prefix}btn-border-color: #{$suvanto-green};
      --#{$prefix}btn-hover-border-color: #{$suvanto-green};
      --#{$prefix}btn-hover-bg: #{$suvanto-green};
      --#{$prefix}btn-hover-color: #{$white};
    }

    .nav-tabs {
      --#{$prefix}nav-tabs-link-active-color: #{$primary};
    }
    .accordion {
      --#{$prefix}accordion-active-bg: #{rgba($primary, 0.1)};
    }
    .icon-tab .active.nav-link {
      background: #{$primary}; //#556ee6;
      color: #fff;
    }
    a.nav-link:hover,
    .nav-tabs-custom .nav-item .nav-link.active {
      color: #{$primary} !important;
    }
    .topnav .navbar-nav .nav-item .nav-link.active,
    .topnav .navbar-nav .nav-item .nav-link:hover,
    .topnav .navbar-nav .nav-item .nav-link:focus {
      background: transparent;
      color: #{$primary} !important;
    }
    .dropdown-item:hover {
      color: #{$primary} !important;
    }
    #page-topbar {
      background-color: #{darken($primary, 10%)} !important;
    }

    // group cards
    .group-cards:hover {
      color: #{$primary};
    }

    // btn-link
    .btn-link {
      --#{$prefix}btn-color: #{$primary};
      --#{$prefix}btn-hover-color: #{darken($primary, 4%)};
      --#{$prefix}btn-active-color: #{darken($primary, 4%)};
    }
    .product-view-nav {
      .nav-item {
        .nav-link.active {
          color: #{$white};
          // background-color: #d5eff9;
        }
      }
    }
    .nav-item {
      .nav-link.active {
        color: #{$primary};
        background-color: #d5eff9;
      }
    }
    #navigation-management {
      .navbar-nav {
        .nav-item {
          .nav-link.active {
            color: #{$primary};
            background-color: #{lighten($primary, 40%)};
          }
          .dropdown-menu {
            .dropdown-item {
              &.active,
              &:hover {
                color: #{$primary};
              }
            }
          }
        }
        .nav-link {
          &:focus,
          &:hover {
            color: #{$primary};
            background-color: transparent;
          }
        }

        > .dropdown-toggle {
          &.active {
            > a {
              color: #{$primary};
              background-color: transparent;
            }
          }
        }
      }
    }
    // Bulletins card
    .nav-tabs-custom .nav-item .nav-link.active {
      background-color: transparent;
      color: #{$primary} !important;
    }
    .nav-tabs-custom .nav-item .nav-link.hover {
      background-color: transparent;
      color: #{$primary} !important;
    }
    .nav-tabs-custom .nav-item .nav-link::after {
      background-color: #{$primary};
    }

    // checkbox
    .form-check-input {
      border-color: #{$primary} !important;
    }
    .form-check-input:checked {
      background-color: #{$primary} !important;
    }
    .form-check-input:focus {
      box-shadow: 0 0 0 0.15rem #{$primary} !important;
    }
    li {
      color: #{$primary};
    }

    // ORIG -->
    //topbar
    --#{$prefix}heading-bg: #{$white};
    --#{$prefix}topbar-search-bg: #f3f3f9;
    --#{$prefix}header-item-color: #555b6d;

    --#{$prefix}boxed-body-bg: #ebebf4;

    --#{$prefix}topnav-bg: #{$white};
    --#{$prefix}menu-item-color: #545a6d;
    --#{$prefix}heading-dark-bg: #05255b;

    --#{$prefix}input-border-color: #{$gray-400};
    --#{$prefix}input-focus-border-color: #{darken($gray-400, 10%)};
    --#{$variable-prefix}form-check-input-border: #{rgba($black, 0.1)};

    //footer
    --#{$prefix}footer-bg: #f2f2f5;
    --#{$prefix}footer-color: #74788d;

    --#{$prefix}custom-white: #{$white};
    --#{$prefix}hr-border-color: #{gray-400};

    // TEST
    // ANTD Tab panel
    // ANTD Tab panel
    .ant-tabs-ink-bar {
      background: #00a0d6 !important;
    }
    .ant-tabs-tab:hover {
      color: #00a0d6 !important;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #00a0d6 !important;
    }
    // Antd table
    .clear-header {
      .ant-table-thead > tr > th {
        background-color: white;
      }
    }
    .ant-table-cell-row-hover {
      background-color: rgb(234, 234, 234) !important;
    }
    .ant-table-small {
      font-size: 13px;
    }

    .striped {
      .ant-table-tbody > tr:nth-child(odd) {
        background-color: #fafafa;

        .ant-table-cell-fix-left {
          background-color: #fafafa;
        }
      }

      .ant-table-tbody > tr:nth-child(even) {
        background-color: transparent;

        .ant-table-cell-fix-left {
          background-color: #fff;
        }
      }

      .ant-table-tbody
        > tr.ant-table-row-selected
        > td.ant-table-cell-fix-left {
        background-color: rgb(230, 247, 255);
      }
    }
  }

  // TODO: sometimes overwrites theme-light-1
  // $primary does not work. Has to be changed if primary color changes
  // .topnav .navbar-nav .dropdown-item.active,
  // .topnav .navbar-nav .dropdown-item:hover {
  //   color: #ac52da !important;
  // }
  // .topnav .navbar-nav .dropdown.active > a {
  //   color: #ac52da !important;
  // }
}
